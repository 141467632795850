.notification-header {
  top: 0;
  width: 100vw;
  position: fixed;
  text-align: center;
  background-color: #808080;
  z-index: 1031;
  opacity: 1;
}
.notification-header .message-wrapper {
  padding: 25px 15px;
  cursor: pointer;
}

.notification-header .message {
  color: #362c94;
  font-weight: 500;
  font-size: 18px;
}

.notification-header .success {
  background-color: #f2f9f2;
}
.notification-header .success .message {
  color: #43a047;
}

.notification-header .danger {
  background-color: #fef1f0;
}

.notification-header .danger .message {
  color: #f44336;
}

.notification-header .info {
  background-color: #eef7fe;
}
.notification-header .info .message {
  color: #1e88e5;
}

.notification-header .warning {
  background-color: #fff3e0;
}
.notification-header .warning .message {
  color: #fb8c00;
}

.notification-header .close {
  font-size: 20px;
  margin-right: 10px;
  margin-left: 15px;
}

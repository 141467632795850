body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* / checkbox styles */
.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: #28df99;
}
.styled-checkbox:hover + label:before {
  background: #f35429;
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
  background: #f35429;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/* // checkbox styles end */

/* select dropdown */

.select-dropdown,
.select-dropdown * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}
.select-dropdown {
  position: relative;
  background-color: #e6e6e6;
  border-radius: 4px;
}
.select-dropdown select {
  font-size: 1rem;
  font-weight: normal;
  max-width: 100%;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}
.select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}
.select-dropdown:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
/* end selevct dropdown */

/*
Login page container styles

*/

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #9053c7;
  background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
  background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
  background: linear-gradient(-135deg, #c850c0, #4158d0);
}

.wrap-login100 {
  width: 35%;
  background: #fff;
  border-radius: 10px;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  padding: 130px 130px 110px 130px;
}

.login100-form {
  width: 290px;
}

.login100-form-title {
  font-family: Poppins-Bold;
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;

  width: 100%;
  display: block;
  padding-bottom: 30px;

}

.container-login100-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.login100-form-btn:hover {
  background: #333333;
}

.google-btn {

  font-family: Montserrat-Bold !important;
  font-size: 15px !important;
  line-height: 1.5 !important;
  /* color: #ff5e22 !important; */
  text-transform: uppercase !important;
  width: 100% !important;
  height: 50px !important;
  border-radius: 25px !important;
  /* border-color: #000 !important; */
  /* background: #fff !important; */
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 40px !important;
  -webkit-transition: all 0.4s !important;
  -o-transition: all 0.4s !important;
  -moz-transition: all 0.4s !important;
  transition: all 0.4s !important;

  box-shadow: -4px -2px 11px -1px rgba(0,0,0,0.5)!important;
  -webkit-box-shadow: -4px -2px 11px -1px rgba(0,0,0,0.5)!important;
  -moz-box-shadow: -4px -2px 11px -1px rgba(0,0,0,0.5)!important;

}

.google-btn:hover {
  box-shadow: -4px -2px 11px -1px rgba(0,0,0,0.75)!important;
  -webkit-box-shadow: -4px -2px 11px -1px rgba(0,0,0,0.75)!important;
  -moz-box-shadow: -4px -2px 11px -1px rgba(0,0,0,0.75)!important;

}
.login100-form-btn:hover {
  background: #ff5e22;
  color: #fff;
}


.logo-2 {

  border-radius: 10px;
  /* border: 2px solid #ff5e22; */
  padding: 10px;
  width: 150px;
  height: 150px;
  /* background: #fe5e68; */
}


.login100-pic {
  margin: 20px;
  width: 150px;
  height: 150px;
  background: #fc8019;
  border-radius: 20px;
}

















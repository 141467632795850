.order-details >td {
  padding: 0px;
  border-top: 0px;
  border-left: 6px solid #1bcfb4;
  background: #f2edf3;
}
.order-delivery-details {


//  .product-image {
//         width: 100px;
//         height: 100px;
//       }
}


.selected-order-row>td.order-frequency {
  border-left: 6px solid #1bcfb4;

}

.orders-table  > thead > tr > th {
    font-weight: 800;
  }

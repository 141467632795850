@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #112d42;
}

section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: #03a9f4;
}

section .container {
  position: relative;
  min-width: 1100px;
  min-height: 550px;
  display: flex;
  z-index: 1000;
}

section .container .contactinfo {
  position: relative;
  top: 0px;
  width: 350px;
  height: calc(100%-80px);
  background: #0f3959;
  z-index: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
  min-height: 400px;
}

section .container .contactinfo .slots-container {
}

section .container .contactinfo .slots-container h6 {
  color: #fff
}

section .container .contactinfo .slots-container .slot {
display: flex;

}

section .container .contactinfo .slots-container input {
  width: 100px;
}

section .container .contactinfo h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

section .container .contactinfo .info {
  position: relative;
  margin: 20px 0;
}

section .container .contactinfo .info li {
  position: relative;
  list-style: none;
  display: flex;
  margin: 20px 0;
  cursor: pointer;
  align-items: flex-start;
}

section .container .contactinfo .info li span:nth-child(1) {
  width: 30px;
  min-width: 30px;
}
section .container .contactinfo .info li span:nth-child(1) img {
  max-width: 100%;
  filter: invert(1);
  opacity: 0.5;
}

section .container .contactinfo .info li span:nth-child(2) {
  color: #fff;
  margin-left: 10px;
  font-weight: 300;
  opacity: 0.5;
}

section .container .contactinfo .info li:hover span:nth-child(1) img,
section .container .contactinfo .info li:hover span:nth-child(2) {
  opacity: 1;
}

section .container .contactForm {
  position: absolute;
  padding: 70px 50px;
  background: #fff;
  margin-left: 150px;
  padding-left: 250px;
  width: calc(100%-150px);
  height: 100%;
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.5);
}

section .container .contactForm h2 {
  color: #0f3959;
  font-size: 24px;
  font-weight: 500;
}

section .container .contactForm .formBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}

section .container .contactForm .formBox .inputBox {
  position: relative;
  margin: 0 0 35px 0;
}

section .container .contactForm .formBox .inputBox.w50 {
  width: 47%;
}

section .container .contactForm .formBox .inputBox.w100 {
  width: 100%;
}

section .container .contactForm .formBox .inputBox input,
section .container .contactForm .formBox .inputBox textarea {
  width: 100% !important;
  padding: 5px 0;
  resize: none;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  border: none;
  border-bottom: 1px solid #777;
  outline: none;
}

section .container .contactForm .formBox .inputBox textarea {
  min-height: 90px;
}
section .container .contactForm .formBox .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  transition: 0.5s;
  pointer-events: none;
}

section .container .contactForm .formBox .inputBox input ~ span,
section .container .contactForm .formBox .inputBox textarea ~ span {
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ff568c;
}
/* section .container .contactForm .formBox .inputBox input:focus ~ span,
section .container .contactForm .formBox .inputBox textarea:focus ~ span {
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ff568c;
} */


section .container .contactForm .formBox .inputBox button {
  position: relative;
  cursor: pointer;
  background: #0f3959;
  color: #fff;
  border: none;
  max-width: 150px;
  padding: 12px;
}

section .container .contactForm .formBox .inputBox button:hover {
  background: #ff568c;
}
